import { mapGetters } from 'vuex';
import occasions from '@dundle/locale/data/occasion-slug.json';

const OCCASION_SLUG = 'occasion-slug';
const OCCASION_COOKIE = 'dundle.occasion_date';

export default {
    data() {
        return {
            currentPromotions: [
                {
                    name: 'google_play_royal_match_2024',
                    active: true,
                    start: new Date(2024, 9, 1), // Month is 0-indexed, 9 = August
                    end: new Date(2024, 9, 16), // end date is 00:00 at night, so to include 15th August define the day after as end date
                    regions: ['DE'],
                    languages: { exclude: true, values: ['de'] },
                    banner: 'images/banners/2024/google-play-royal-match/old-home-page/google-play-royal-match-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'google-play' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=google_play_royal_match_2024',
                },
                {
                    name: 'google_play_royal_match_2024',
                    active: true,
                    start: new Date(2024, 6, 16), // Month is 0-indexed, 6 = July
                    end: new Date(2024, 7, 1), // end date is 00:00 at night, so to include 31 July define the day after as end date
                    regions: ['BE'],
                    languages: { exclude: true, values: ['fr', 'nl'] },
                    banner: 'images/banners/2024/google-play-royal-match/old-home-page/google-play-royal-match-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'google-play' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=google_play_royal_match_2024',
                },
                {
                    name: 'neosurf_giveaway_july_2024',
                    active: true,
                    start: new Date(2024, 6, 1),
                    end: new Date(2024, 6, 14), // Month is 0-indexed, 6 = July
                    regions: ['IT', 'ES'],
                    languages: { exclude: false, values: ['it', 'es'] },
                    banner: 'images/banners/2024/neosurf-giveaway-june/home-neosurf-giveaway-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'neosurf' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=neosurf_giveaway_july_2024',
                },
                {
                    name: 'google_play_royal_match_2024',
                    active: true,
                    start: new Date(2024, 6, 1),
                    end: new Date(2024, 6, 15), // Month is 0-indexed, 6 = July
                    regions: ['FR'],
                    languages: { exclude: true, values: ['fr'] },
                    banner: 'images/banners/2024/google-play-royal-match/old-home-page/google-play-royal-match-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'google-play' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=google_play_royal_match_2024',
                },
                {
                    name: 'google_bonus_value_campaign_july_2024',
                    active: true,
                    start: new Date(2024, 6, 1),
                    end: new Date(2024, 6, 15), // Month is 0-indexed, 6 = July
                    regions: ['DE', 'BE', 'AT'],
                    languages: { exclude: true, values: ['de', 'nl', 'fr'] },
                    banner: 'images/banners/2024/google-play-bonus-value/old-home-page/google-play-bonus-value-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'google-play' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=google_bonus_value_campaign_july_2024',
                },
                {
                    name: 'jetoncash_giveaway_june_2024',
                    active: true,
                    start: new Date(2024, 5, 17),
                    end: new Date(2024, 6, 1), // Month is 0-indexed, 6 = July
                    regions: ['DE', 'AT', 'NL', 'FR', 'BE', 'PL'],
                    languages: { exclude: false, values: ['de', 'nl', 'fr', 'pl', 'en'] },
                    banner: 'images/banners/2024/jeton-cash/dundle_jeton_homepage_old_',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'jeton-cash' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=jetoncash_giveaway_june_2024',
                },
                {
                    name: 'google_play_warcraft_promotion_2024',
                    active: true,
                    start: new Date(2024, 5, 1),
                    end: new Date(2024, 5, 16),
                    regions: ['DE'],
                    languages: { exclude: true, values: ['de'] },
                    banner: 'images/banners/2024/google-play-warcraft/warcraft-2024-homepage-banner-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'google-play' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=google_play_warcraft_promotion_2024',
                },
                {
                    name: 'PUBG_giveaway_may_2024',
                    active: true,
                    start: new Date(2024, 4, 13), // Month is 0-indexed, 4 = May
                    end: new Date(2024, 4, 27),
                    regions: ['DE', 'NL', 'BE', 'AT', 'UK', 'GB'],
                    languages: {
                        exclude: false,
                        values: ['de', 'en', 'nl', 'fr'],
                    },
                    banner: 'images/banners/2024/pubg/pubg-2024-homepage-banner-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'pubg-mobile-uc' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=PUBG_giveaway_may_2024',
                },
                {
                    name: 'flexepin_discount_april_2024',
                    active: true,
                    start: new Date(2024, 3, 15),
                    end: new Date(2024, 4, 12),
                    regions: ['DE', 'AT'],
                    languages: { exclude: true, values: ['de'] },
                    banner: 'images/banners/2024/flexepin/flexepin-deal-2024-homepage-banner-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'brand', params: { slug: 'flexepin' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=flexepin_discount_april_2024',
                },
                {
                    name: 'christmas_2023',
                    active: true,
                    start: new Date(2023, 11, 15),
                    end: new Date(2024, 0, 1),
                    regions: ['DE', 'NL', 'BE', 'FR', 'AT', 'GB'],
                    languages: { exclude: false, values: ['de', 'nl', 'en', 'fr'] },
                    banner: 'images/banners/2023/christmas/home-page/home-page-banner-christmas-2023-',
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: OCCASION_SLUG, params: { slug: 'christmas' } },
                    utm: '?utm_source=website&utm_medium=homepage_banner&utm_campaign=christmas_2023',
                },
                {
                    name: 'app',
                    active: true,
                    end: undefined,
                    regions: [
                        'DE',
                        'NL',
                        'BE',
                        'FR',
                        'US',
                        'GB',
                        'AU',
                        'AT',
                        'CA',
                        'CH',
                        'IE',
                        'LU',
                        'NZ',
                        'PL',
                        'ES',
                        'MX',
                        'IN',
                        'AE',
                        'MT',
                        'LT',
                        'LV',
                        'BR',
                        'PT',
                        'IT',
                        'BG',
                        'CZ',
                        'HR',
                        'HU',
                        'LV',
                        'RO',
                    ],
                    languages: { exclude: true, values: ['de', 'nl', 'en', 'fr', 'pl', 'es', 'pt', 'it'] },
                    banner: `images/banners/2023/promotion-app/dundle-app-promotion-`,
                    bannerImageFormat: '.png',
                    rawSrc: false,
                    path: { name: 'app' },
                },
            ],
            raffles: [
                {
                    products: ['jetoncash-code-100-eur', 'jetoncash-code-150-eur'],
                    start: new Date(2024, 5, 17),
                    end: new Date(2024, 5, 30),
                    regions: ['DE', 'AT', 'NL', 'BE', 'FR', 'PL'],
                    languages: ['de', 'nl', 'fr', 'pl', 'en'],
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            theme: 'occasion/getActiveOccasion',
        }),
        isMarketingRegion() {
            return promotion => {
                const promotionObject = this.currentPromotions.find(p => p.name === promotion);
                if (!promotionObject.regions) return true;
                return promotionObject.regions.includes(this.$locale.regio);
            };
        },
        isMarketingLanguage() {
            return promotion =>
                this.currentPromotions.find(p => p.name === promotion).languages.values.includes(this.$locale.language);
        },
        supportsPromotion() {
            return promotion => {
                const marketingPromotion = this.getPromotionByName(promotion);

                if (!marketingPromotion) {
                    console.error('No marketing campaign found for given slug: ' + promotion);
                    return;
                }

                if (!marketingPromotion.active) return;

                if (marketingPromotion.end !== undefined) {
                    const date = this.$cookies.get(OCCASION_COOKIE);
                    const currentDate = date ? new Date(date.substring(0, 10)) : new Date();

                    if (!this.dateInRange(currentDate, marketingPromotion.start, marketingPromotion.end)) return;
                }

                const displayInLanguage = marketingPromotion.languages.exclude
                    ? this.isMarketingLanguage(promotion)
                    : true;

                return this.isMarketingRegion(promotion) && displayInLanguage;
            };
        },
        marketingBanner() {
            const promotion = this.getPromotionByName(this.getActiveMarketingPromotion().name);

            return {
                banner:
                    promotion.banner +
                    (this.isMarketingLanguage(promotion.name) ? this.$locale.language.toUpperCase() : 'EN') +
                    promotion.bannerImageFormat,
                rawSrc: promotion.rawSrc,
            };
        },
        ribbonSupportsPromotion() {
            return promotion => {
                const marketingPromotion = this.getPromotionByName(promotion);

                if (!marketingPromotion) {
                    console.error('No marketing campaign found for given slug: ' + promotion);
                    return;
                }

                if (!marketingPromotion.active) return;

                const displayInLanguage = marketingPromotion.languages.exclude
                    ? this.isMarketingLanguage(promotion)
                    : true;
                return this.isMarketingRegion(promotion) && displayInLanguage;
            };
        },
    },
    methods: {
        dateInRange(d, start, end) {
            // Checks if date in d is between dates in start and end.
            // Returns a boolean or NaN:
            //    true  : if d is between start and end (inclusive)
            //    false : if d is before start or after end
            //    NaN   : if one or more of the dates is illegal.
            // NOTE: The code inside isFinite does an assignment (=).
            return isFinite((d = d.valueOf())) && isFinite((start = start.valueOf())) && isFinite((end = end.valueOf()))
                ? start <= d && d <= end
                : NaN;
        },
        getActiveMarketingPromotion() {
            for (const promotion of this.currentPromotions) {
                if (this.supportsPromotion(promotion.name)) {
                    return promotion;
                }
            }
        },
        getActivePromotionPath() {
            const promotion = this.getActiveMarketingPromotion();

            if (!promotion.path) {
                return null;
            }

            if (promotion.path.name === OCCASION_SLUG) {
                const language = this.$locale.language;
                const occasionPath = promotion.path.params.slug;
                promotion.path.params.slug = occasions[language]?.[occasionPath] ?? promotion.path.params.slug;
            }
            if (promotion.path.name === 'brand') {
                return `${this.$nuxt.$locale.path({
                    name: 'brand',
                    params: {
                        brand: promotion.path.params.slug,
                    },
                    trailingSlash: true,
                })}${promotion.utm ? promotion.utm : ''}`;
            }

            const path = `${this.$nuxt.$locale.path(promotion.path)}${promotion.utm ? promotion.utm : ''}`;
            return path;
        },
        getPromotionByName(name) {
            return this.currentPromotions.find(p => p.name === name);
        },
        enteredRaffle(products) {
            const raffle = this.raffles.find(raffle =>
                products.some(product => raffle.products.includes(product.productId))
            );
            if (raffle) {
                const isRaffleActive = this.dateInRange(new Date(), raffle.start, raffle.end);
                const isInRegionLanguage =
                    raffle.regions.includes(this.$locale.regio) && raffle.languages.includes(this.$locale.language);
                return isRaffleActive && isInRegionLanguage;
            }
            return false;
        },
    },
};
