<!-- ATTENTION: SVG files are not allowed to run through the CDN -->
<template>
    <nuxt-resource
        :lazy="lazy"
        :full-u-r-l="true"
        :src="srcString"
        :srcset="srcSet"
        :sizes="sizesString"
        :alt="alt"
        :width="width"
        :height="height"
        :ratio="ratio"
        @error="setFallback"
    ></nuxt-resource>
</template>

<script>
export default {
    props: {
        lazy: {
            type: Boolean,
            required: false,
            default: true,
        },
        src: {
            type: String,
            required: true,
        },
        rawSrc: {
            type: Boolean,
            required: false,
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
        fit: {
            type: String,
            validator: value => {
                return ['scale-down', 'contain', 'cover', 'crop'].includes(value);
            },
        },
        quality: {
            type: Number,
            required: false,
            default: 85,
        },
        responsiveWidths: {
            type: Array,
            required: false,
        },
        responsiveSizes: {
            type: Array,
            required: false,
        },
        sizes: {
            type: String,
            required: false,
        },
        fallBack: {
            type: String,
            required: false,
        },
        alt: {
            type: String,
            required: false,
            default: '',
        },
        resource: {
            type: Boolean,
            required: false,
            default: false,
        },
        ratio: {
            type: String,
            default: undefined,
            required: false,
        },
    },

    data() {
        return {
            error: false,
        };
    },

    computed: {
        srcSet() {
            let domain = '';
            if (this.resource) {
                domain = 'https://cdn.dundle.com/resources/';
            }
            if (this.responsiveSizes) {
                let srcSetString = '';
                this.responsiveSizes.forEach(size => {
                    srcSetString +=
                        'https://dundle.com/cdn-cgi/image/' +
                        this.getOptions(size.width) +
                        '/' +
                        domain +
                        size.src +
                        ' ' +
                        size.width +
                        'w' +
                        ',';
                });
                return srcSetString;
            } else {
                if (this.responsiveWidths) {
                    let srcSetString = '';
                    this.responsiveWidths.forEach(width => {
                        srcSetString +=
                            'https://dundle.com/cdn-cgi/image/' +
                            this.getOptions(width) +
                            '/' +
                            domain +
                            this.src +
                            ' ' +
                            width +
                            'w' +
                            ',';
                    });
                    if (this.width) {
                        srcSetString +=
                            'https://dundle.com/cdn-cgi/image/' +
                            this.getOptions(this.width) +
                            '/' +
                            domain +
                            this.src +
                            ' ' +
                            this.width +
                            'w' +
                            ',';
                    }
                    return srcSetString;
                }
                return null;
            }
        },
        sizesString() {
            if (this.sizes) {
                return this.sizes;
            } else if (this.width) {
                return this.width + 'px';
            }
            return null;
        },
        srcString() {
            if (this.rawSrc) {
                return this.src;
            }

            let domain = '';
            if (this.resource) {
                domain = 'https://cdn.dundle.com/resources/';
            }

            if (!this.error)
                return 'https://dundle.com/cdn-cgi/image/' + this.getOptions(this.width) + '/' + domain + this.src;

            domain = 'https://cdn.dundle.com/resources/';
            if (this.fallBack.startsWith('http')) domain = '';
            return domain + this.fallBack;
        },
    },

    methods: {
        getOptions(width) {
            // It is always benificial to use format auto, as browsers that do not support it will get a png or jpg but modern browser will get webp
            let options = 'format=auto';
            if (width) {
                options += ',width=' + width;
            }
            if (this.height) {
                options += ',height=' + this.height;
            }
            if (this.fit) {
                options += ',fit=' + this.fit;
            }
            if (this.quality) {
                options += ',quality=' + this.quality;
            }
            return options;
        },
        setFallback(event) {
            if (this.fallBack) {
                event.target.src = this.fallBack;
                this.error = true;
            }
        },
    },
};
</script>
